// Import jQuery
import $ from 'jquery';
import { BROCHURE_LINK, CONSOLE_LOG, PHP_SERVER_URL } from './constants';

export const HAS_EXTERNAL_PATH = hasExternalPathFunction();

if (CONSOLE_LOG) console.log("HAS_EXTERNAL_PATH:", HAS_EXTERNAL_PATH);

const brBtnlinks = document.querySelectorAll('a[data-br-download]');
if(brBtnlinks){
    brBtnlinks.forEach(link => {
        const key = link.getAttribute('data-br-download');
        if (BROCHURE_LINK[key]) {
            link.setAttribute('href', BROCHURE_LINK[key]);  // Set the href
        }
    });
}


function hasExternalPathFunction() {
    const path = window.location.pathname;
    return path.split('/').length > 2;
}

// Function to shuffle the testimonials array
export function shuffleArrayObject(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

export function getCurrentPageIndex() {
  // Get the current URL
  const currentUrl = window.location.href;

  // Parse the URL to get the path
  const url = new URL(currentUrl);
  const path = url.pathname;

  // Split the path into segments
  const segments = path.split('/').filter(segment => segment.length > 0);

  // Determine the current index
  const currentIndex = segments.length;

  // Output the result
  // if (CONSOLE_LOG) console.log(`CurrentURL: ${currentUrl}`);
  // if (CONSOLE_LOG) console.log(`CurrentIndex: ${currentIndex}`);
  
  return currentIndex;
}

export function modifyPageURL(pageUrl) {
  // Determine the current index
  const currentIndex = getCurrentPageIndex();
  if (CONSOLE_LOG) console.log("currentIndex - ", currentIndex);

  // Check if the URL should be skipped
  if (!shouldSkipUrl(pageUrl)) { // Fixed the 'url' to 'pageUrl' here
    if (currentIndex === 2) {
      pageUrl = "../../" + pageUrl;
    } else if (currentIndex === 3) {
      pageUrl = "../../" + pageUrl;
    } else {
      pageUrl = "../" + pageUrl;
    }
  }

  // Output the result
  //if (CONSOLE_LOG) console.log(`PageURL: ${pageUrl}`);
  return pageUrl;
}

// The `shouldSkipUrl` function definition should be outside or imported
export function shouldSkipUrl(url) {
  const skipPrefixes = ['https://', 'mailto:', 'tel:'];
  return skipPrefixes.some(prefix => url.startsWith(prefix));
}


export function hidePreloader(){
    // Select the element with the class 'preloader'
    const preloader = document.querySelector('.preloader');

    // Apply a fade-out effect
    preloader.style.transition = 'opacity 0.5s ease'; // Set transition for fade-out
    preloader.style.opacity = 0; // Fade out by setting opacity to 0

    // After the fade-out, hide the element
    setTimeout(() => {
        preloader.style.display = 'none'; // Hide the element after fading out
    }, 500); // Match the timeout duration with the fade-out duration
}


export async function fetchDataFromPHP(reqMethod, endPoints, formObject) {
    try {
        // Determine the method and body based on reqMethod
        const method = reqMethod === 1 ? 'POST' : 'GET';
        const body = reqMethod === 1 ? JSON.stringify(formObject) : undefined;

        const response = await fetch(`${PHP_SERVER_URL}${endPoints}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 65dd356c8096259b546cb14cf9639cc54604444ff0ee8dc5d9b27243954e401a' // Add your token if necessary
            },
            body: body // Only include body if it's defined
        });

        const data = await response.json();
        if (CONSOLE_LOG) console.log('Response from PHP:', data);

        if (data.status === 200) {
            return data;
        } else {
            alert(data.message);
        }
        return false;
    } catch (error) {
        console.error('Error:', error);
        alert('There was an error. Kindly try again after some time.');
        return false;
    }
}


async function submitForm() {
    const endPoints = '/php/create-checkout-url.php';
    const formObject = {
        field1: 'value1',
        field2: 'value2'
    };

    const result = await fetchDataFromPHP(1, endPoints, formObject);

    if (result) {
        console.log('Form submitted successfully:', result);
    } else {
        console.log('Form submission failed.');
    }
}

//submitForm();


  // Function to create and display the modal
  function createAndShowModal(title, body, btns) {
      // Start building the button HTML
      let buttonsHtml = '';

      // Check if t1 exists and add the primary button
      if (btns.t1) {
          buttonsHtml += `
              <button type="button" class="btn btn-primary" id="primaryButton">${btns.t1}</button>
          `;
      }

      // Check if t2 exists and add the secondary button
      if (btns.t2) {
          buttonsHtml += `
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="secondaryButton">${btns.t2}</button>
          `;
      }

      // Create the modal HTML
      const modalHtml = `
          <div class="modal fade" id="dynamicModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="dynamicModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h1 class="modal-title fs-5" id="dynamicModalLabel">${title}</h1>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          ${body}
                      </div>
                      <div class="modal-footer">
                          ${buttonsHtml}
                      </div>
                  </div>
              </div>
          </div>
      `;

      // Append modal to the body
      $('body').append(modalHtml);

      // Show the modal using Bootstrap's jQuery API
      const modalElement = $('#dynamicModal');
      modalElement.modal('show');

      // Handle primary button click
      $('#primaryButton').on('click', function () {
          if (btns.t1Link !== 0) {
              window.location.href = btns.t1Link; // Redirect to the specified link
          } else {
              modalElement.modal('hide'); // Just hide the modal if no link is provided
          }
      });

      // Remove the modal from DOM when it is closed
      modalElement.on('hidden.bs.modal', function () {
          modalElement.remove();
      });
  }


  // Trigger the modal
  //createAndShowModal('Form Submission Successful', 'Your form has been submitted successfully!', { t1: "Okay", t1Link: 0 });




